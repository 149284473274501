/* eslint-disable import/prefer-default-export */
import { mutate } from "swr";

export const makeSWRKey = <T extends string>(
    type: string,
    options: Partial<Record<T, unknown>>,
) => ({
    type,
    options,
});

export const mutateSWRType = async (type: string) => mutate((k) => typeof k === "object"
    && k != null
    && "type" in k
    && k.type === type,
undefined,
{ revalidate: true });
