import { resultsArray } from "@adaptable/utils";
import { useRouter } from "next/router";
import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import useSWR, { mutate } from "swr";

import { appUrls, routerCatch } from "utils";
import { useAuth } from "utils/auth";
import { makeSWRKey, mutateSWRType } from "utils/swr";

import { registerApiImpl, useApi } from "./api";
import feathersClient, { NotFound } from "./feathers-client";
import {
    AcceptOrganizationInviteOptions,
    CreateOrganizationInviteOptions,
    CreateOrganizationOptions,
    DeleteOrganizationByIdOptions,
    DeleteOrganizationInviteByIdOptions,
    FetchOrgIdsWhereOwnerOptions,
    FetchOrganizationInvitesOptions,
    FetchOrganizationMembersOptions,
    FetchOrganizationsOptions,
    OrgMember,
    Organization,
    OrganizationsResponse,
    RemoveOrganizationMembersOptions,
    ResendOrganizationInviteEmailOptions,
    UpdateOrganizationMemberRolesOptions,
} from "./types";

export { orgNameSchema } from "@adaptable/client/dist/api-types/organizations";

type OrgIdOrNull = Organization["_id"] | null;
interface OrgIdObj {
    defaultOrgId: OrgIdOrNull;
    [userId: string]: OrgIdOrNull;
}

export interface OrgInfo {
    currentOrgId: OrgIdOrNull;
    setCurrentOrgId: Dispatch<SetStateAction<OrgIdOrNull>>;
}

export interface OrgInfoPerUserIds {
    currentOrgId: OrgIdObj;
    setCurrentOrgId: OrgInfo["setCurrentOrgId"];
}

export const OrganizationContext = createContext<OrgInfo | OrgInfoPerUserIds>({
    currentOrgId: null,
    setCurrentOrgId: () => { throw new Error("No organization context set"); },
});

export const isPlainCurrentOrgId = (id: OrgIdOrNull | OrgIdObj): id is OrgIdOrNull => id === null || typeof id === "string";
// eslint-disable-next-line max-len
export const isPlainOrgInfo = (obj: OrgInfo | OrgInfoPerUserIds): obj is OrgInfo => isPlainCurrentOrgId(obj.currentOrgId);

export const useCurrentOrganizationId = (): OrgInfo => {
    const { user } = useAuth();
    const orgInfo = useContext(OrganizationContext);
    if (isPlainOrgInfo(orgInfo)) return orgInfo;
    if (user == null) {
        return {
            currentOrgId: orgInfo.currentOrgId.defaultOrgId,
            setCurrentOrgId: orgInfo.setCurrentOrgId,
        };
    }
    if (orgInfo.currentOrgId[user._id] == null) {
        return {
            currentOrgId: null,
            setCurrentOrgId: orgInfo.setCurrentOrgId,
        };
    }
    return {
        currentOrgId: orgInfo.currentOrgId[user._id],
        setCurrentOrgId: orgInfo.setCurrentOrgId,
    };
};

export const useSetCurrentOrganizationIdOnce = (orgId: string | null | undefined) => {
    const { currentOrgId, setCurrentOrgId } = useCurrentOrganizationId();
    const [orgIdSetOnce, setOrgIdSetOnce] = useState(false);
    const router = useRouter();

    // Change the current organization if it doesn't match the app
    useEffect(() => {
        if (orgId == null) return;
        if (currentOrgId !== orgId) {
            if (orgIdSetOnce) {
                router.push(appUrls.dashboard).catch(routerCatch());
            } else {
                setCurrentOrgId(orgId);
            }
        }
        setOrgIdSetOnce(true);
    }, [currentOrgId, orgId, orgIdSetOnce, router, setCurrentOrgId]);

    if (orgId === undefined) return { inProgress: false };
    if (orgId === null) return { inProgress: true };
    if (currentOrgId !== orgId) return { inProgress: true };
    return { inProgress: false };
};

const createOrganization = async (options: CreateOrganizationOptions): Promise<Organization> => {
    const client = feathersClient();
    const ret = await client.service("organizations").create(options);
    await mutateSWRType("organizations");
    return ret;
};

const fetchOrganizations = async ({
    orgId,
    userId,
    skip = 0,
    limit = 100,
}: FetchOrganizationsOptions): Promise<OrganizationsResponse> => {
    const client = feathersClient();
    return client.service("organizations").find({
        query: {
            _id: orgId ?? undefined,
            orgMembers: userId
                ? {
                    userId: userId ?? undefined,
                    deletedAt: null,
                }
                : undefined,
            status: {
                $ne: "deleted",
            },
            $limit: limit,
            $skip: skip,
            $sort: {
                name: 1,
            },
        },
    });
};

const fetchOrganizationInvites = async ({
    orgId,
    skip = 0,
    limit = 100,
}: FetchOrganizationInvitesOptions) => {
    const client = feathersClient();
    return client.service("orgInvites").find({
        query: {
            orgId,
            status: "pending",
            $limit: limit,
            $skip: skip,
            $sort: {
                createdAt: 1,
            },
        },
    });
};

const fetchOrganizationMembers = async ({
    orgId,
    userId,
    skip = 0,
    limit = 100,
}: FetchOrganizationMembersOptions) => {
    const client = feathersClient();
    return client.service("orgMembers").find({
        query: {
            orgId,
            userId,
            deletedAt: null,
            $limit: limit,
            $skip: skip,
            $sort: {
                createdAt: 1,
            },
        },
    });
};

const fetchOrgIdsWhereOwner = async ({
    userId,
}: FetchOrgIdsWhereOwnerOptions) => {
    const client = feathersClient();
    const members = resultsArray<OrgMember>(await client.service("orgMembers").find({
        query: {
            userId,
            roles: {
                $contains: ["owner"],
            },
            deletedAt: null,
            $limit: 100,
            $sort: {
                createdAt: 1,
            },
        },
    }));
    return members.map((m) => m.orgId);
};

const createOrganizationInvite = async (options: CreateOrganizationInviteOptions) => {
    const client = feathersClient();
    const ret = await client.service("orgInvites").create(options);
    await mutateSWRType("orgInvites");
    return ret;
};

const deleteOrganizationInviteById = async ({ inviteId }: DeleteOrganizationInviteByIdOptions) => {
    const client = feathersClient();
    const ret = await client.service("orgInvites").remove(inviteId);
    await mutateSWRType("orgInvites");
    return ret;
};

const acceptOrganizationInvite = async ({ inviteId }: AcceptOrganizationInviteOptions) => {
    const client = feathersClient();

    const ret = await client.service("orgInvites/reply").create({
        token: inviteId,
        reply: "accept",
    });
    await mutateSWRType("orgInvites");
    await mutateSWRType("orgMembers");
    return ret;
};
// eslint-disable-next-line max-len
const resendOrganizationInviteEmail = async (options: ResendOrganizationInviteEmailOptions) => {
    const client = feathersClient();

    return client.service("orgInvites").patch(options.inviteId, { resend: true });
};

const deleteOrganizationById = async ({ orgId }: DeleteOrganizationByIdOptions): Promise<void> => {
    const client = feathersClient();
    const ret = await client.service("organizations").remove(orgId);
    await mutateSWRType("organizations");
    await mutateSWRType("orgMembers");
    await mutateSWRType("orgInvites");
    return ret;
};

const removeOrganizationMembers = async ({
    memberIds,
}: RemoveOrganizationMembersOptions): Promise<void> => {
    const client = feathersClient();
    let count = 0;
    let error: Error | null = null;

    for (const memberId of memberIds) {
        try {
            // eslint-disable-next-line no-await-in-loop
            await client.service("orgMembers").remove(memberId);
            count += 1;
        } catch (err) {
            error = err;
            break;
        }
    }

    if (count > 0) {
        await mutateSWRType("orgMembers");
    }

    if (error) {
        const err = new Error(`Error deleting member with id ${memberIds[count]} (deleted ${count}/${memberIds.length}): ${error.message}`);
        (err as any).orig = error;
        throw err;
    }
};

const updateOrganizationMemberRoles = async ({
    memberIds,
    roles,
}: UpdateOrganizationMemberRolesOptions): Promise<void> => {
    const client = feathersClient();
    let count = 0;
    let error: Error | null = null;

    for (const memberId of memberIds) {
        try {
            // eslint-disable-next-line no-await-in-loop
            await client.service("orgMembers").patch(memberId, { roles });
            count += 1;
        } catch (err) {
            error = err;
            break;
        }
    }

    if (count > 0) {
        await mutateSWRType("orgMembers");
    }

    if (error) {
        const err = new Error(`Error updating roles for member with id ${memberIds[count]} (updated ${count}/${memberIds.length}): ${error.message}`);
        (err as any).orig = error;
        throw err;
    }
};

// Hooks
const swrOptions = {
    revalidateOnMount: true,
    refreshInterval: 5000,
} as const;

export const useOrganizations = ({
    orgId,
    userId,
    limit = 100,
    skip = 0,
}: FetchOrganizationsOptions) => {
    const api = useApi();
    const options = {
        orgId, userId, limit, skip,
    };

    const key = orgId === null || userId === null ? null : makeSWRKey("organizations", options);
    return useSWR(
        key,
        async () => {
            if (orgId === null) {
                return undefined;
            }
            if (userId === null) {
                return undefined;
            }
            return api.fetchOrganizations(options);
        },
        swrOptions,
    );
};

export const useOrganizationMembers = ({
    orgId,
    userId,
    limit = 100,
    skip = 0,
}: {
    orgId: string | null;
    userId?: string | null;
    limit?: number;
    skip?: number;
}) => {
    const api = useApi();
    const { user } = useAuth();
    const options = {
        orgId,
        userId,
        limit,
        skip,
    };
    const key = makeSWRKey("orgMembers", options);
    return useSWR(key, async () => {
        if (orgId === null) {
            return undefined;
        }
        if (userId === null) {
            return undefined;
        }
        if (user == null && userId != null) {
            return undefined;
        }

        return api.fetchOrganizationMembers({
            ...options,
            // TS doesn't correctly narrow options, so reapply orgId after
            // we've narrowed that.
            orgId,
        });
    }, swrOptions);
};

export const useOrganization = ({ orgId }: { orgId: string | null }) => {
    const { data, error, isLoading } = useOrganizations({ orgId, limit: 1 });
    if (error) return { org: null, error, isLoading };
    if (data == null) return { org: null, isLoading };
    if (data.data.length === 0) return { error: new NotFound("Organization not found") };
    return { org: data.data[0], isLoading };
};

export const useCurrentOrganization = () => {
    const { currentOrgId } = useCurrentOrganizationId();
    return useOrganization({ orgId: currentOrgId });
};

export const useOrganizationMember = ({
    orgId,
    userId,
}: { orgId: string | null, userId: string | null }) => {
    const { data, error, isLoading } = useOrganizationMembers({ orgId, userId, limit: 1 });

    if (error) return { member: null, error, isLoading };
    if (data == null) return { member: null, isLoading };
    if (data.data.length === 0) return { error: new NotFound("Member not found") };
    return { member: data.data[0], isLoading };
};

export const useOrganizationMemberForCurrentUser = ({ orgId }: { orgId: string | null }) => {
    const { user } = useAuth();
    const memInfo = useOrganizationMember({ orgId, userId: user?._id ?? null });
    if (user == null) return { member: null, isLoading: false, error: new Error("Not logged in") };
    return memInfo;
};

// Invites

export interface UseOrgInvitesOptions {
    orgId: string | null;
    limit?: number;
    skip?: number;
}
export const useOrganizationInvites = ({
    orgId,
    limit = 100,
    skip = 0,
}: UseOrgInvitesOptions) => {
    const api = useApi();
    const options = {
        orgId,
        limit,
        skip,
    };
    const key = makeSWRKey("orgInvites", options);
    return useSWR(key, async () => {
        if (orgId === null) {
            return undefined;
        }
        return api.fetchOrganizationInvites(options as FetchOrganizationInvitesOptions);
    }, swrOptions);
};

export const mutateOrgInvites = async (options: UseOrgInvitesOptions) => mutate(makeSWRKey("orgInvites", options));

// Helpers
export { canAddOrgMembers, isOrgOwner } from "@adaptable/client/dist/api-types/orgMembers";
export { isPausedOrg, isPersonalOrg } from "@adaptable/client/dist/api-types/organizations";

registerApiImpl("createOrganization", createOrganization);
registerApiImpl("fetchOrganizations", fetchOrganizations);
registerApiImpl("deleteOrganizationById", deleteOrganizationById);
registerApiImpl("fetchOrganizationInvites", fetchOrganizationInvites);
registerApiImpl("fetchOrganizationMembers", fetchOrganizationMembers);
registerApiImpl("fetchOrgIdsWhereOwner", fetchOrgIdsWhereOwner);
registerApiImpl("removeOrganizationMembers", removeOrganizationMembers);
registerApiImpl("updateOrganizationMemberRoles", updateOrganizationMemberRoles);
registerApiImpl("createOrganizationInvite", createOrganizationInvite);
registerApiImpl("deleteOrganizationInviteById", deleteOrganizationInviteById);
registerApiImpl("acceptOrganizationInvite", acceptOrganizationInvite);
registerApiImpl("resendOrganizationInviteEmail", resendOrganizationInviteEmail);
