import { createContext, useContext } from "react";
import { Api } from "./types";

function notRegistered<N extends keyof Api>(name: N): Api[N] {
    return () => {
        throw new Error(`No function for Api '${name}' is registered`);
    };
}

// Exported for testing
export class ApiImpl implements Api {
    // apps endpoint
    createApp = notRegistered("createApp");
    deleteAppById = notRegistered("deleteAppById");
    fetchAppByName = notRegistered("fetchAppByName");
    fetchAppDetail = notRegistered("fetchAppDetail");
    fetchAppList = notRegistered("fetchAppList");
    updateAppConfig = notRegistered("updateAppConfig");

    createAppRevision = notRegistered("createAppRevision");
    fetchAppActivity = notRegistered("fetchAppActivity");
    fetchAppActivitiesList = notRegistered("fetchAppActivitiesList");
    fetchAppContainers = notRegistered("fetchAppContainers");
    fetchAppDbs = notRegistered("fetchAppDbs");
    fetchAppRevision = notRegistered("fetchAppRevision");
    fetchRepoInfo = notRegistered("fetchRepoInfo");
    fetchAppNameFree = notRegistered("fetchAppNameFree");
    fetchLogs = notRegistered("fetchLogs");
    fetchMetrics = notRegistered("fetchMetrics");
    fetchLoadBalancerList = notRegistered("fetchLoadBalancerList");
    fetchDomains = notRegistered("fetchDomains");

    fetchBuildList = notRegistered("fetchBuildList");

    createStarterJob = notRegistered("createStarterJob");
    fetchStarterJob = notRegistered("fetchStarterJob");

    dnsQuery = notRegistered("dnsQuery");

    kickDomainSyncTask = notRegistered("kickDomainSyncTask");
    notifyResourceInfoChanged = notRegistered("notifyResourceInfoChanged");

    checkout = notRegistered("checkout");
    checkoutSetup = notRegistered("checkoutSetup");
    createPortalSession = notRegistered("createPortalSession");
    fetchInvoiceList = notRegistered("fetchInvoiceList");

    createSubscription = notRegistered("createSubscription");
    cleanupStaleSubscriptions = notRegistered("cleanupStaleSubscriptions");
    fetchSubscriptionList = notRegistered("fetchSubscriptionList");
    removeSubscription = notRegistered("removeSubscription");

    fetchUser = notRegistered("fetchUser");

    createOrganization = notRegistered("createOrganization");
    fetchOrganizations = notRegistered("fetchOrganizations");
    deleteOrganizationById = notRegistered("deleteOrganizationById");
    fetchOrganizationMembers = notRegistered("fetchOrganizationMembers");
    fetchOrgIdsWhereOwner = notRegistered("fetchOrgIdsWhereOwner");
    fetchOrganizationInvites = notRegistered("fetchOrganizationInvites");
    removeOrganizationMembers = notRegistered("removeOrganizationMembers");
    updateOrganizationMemberRoles = notRegistered("updateOrganizationMemberRoles");
    createOrganizationInvite = notRegistered("createOrganizationInvite");
    deleteOrganizationInviteById = notRegistered("deleteOrganizationInviteById");
    acceptOrganizationInvite = notRegistered("acceptOrganizationInvite");
    resendOrganizationInviteEmail = notRegistered("resendOrganizationInviteEmail");

    registerImpl<N extends keyof Api>(name: N, impl: Api[N]) {
        (this as any)[name] = impl;
    }
}

const apiImpl = new ApiImpl();

export function registerApiImpl<N extends keyof Api>(name: N, impl: Api[N]) {
    apiImpl.registerImpl(name, impl);
}

export const ApiContext = createContext<Api>(apiImpl);

export function useApi(): Api {
    return useContext(ApiContext);
}
