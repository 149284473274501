import makeStyles from "@mui/styles/makeStyles";
import cx from "classnames";
import React from "react";

const useStyles = makeStyles({
    root: {
        backgroundColor: "transparent",
        border: "none",
        color: "#007bff", // Same as bootstrap's CSS
        cursor: "pointer",
        display: "inline",
        margin: 0,
        padding: 0,
        "&:hover": {
            color: "#0056b3",
            textDecoration: "none",
        },
    },
});

/**
 * A <button> component that is styled to look like a standard text link.
 *
 * Use this component instead of an <a> tag with an invalid href, as
 * (reluctantly) recommended by eslint rule jsx-a11y/anchor-is-valid.
 */
const LinkButton = (props: JSX.IntrinsicElements["button"]) => {
    const { className, ...rest } = props;
    const { root } = useStyles();

    return (
        <button
            type="button"
            className={cx(className, root)}
            {...rest}
        />
    );
};
export default LinkButton;
